/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';

/**
 * Product price
 * @class ProductPrice
 * @namespace Tigerone/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    priceLabelTypeMap = {
        [PRODUCT_TYPE.simple]: __('Starting at'),
        [PRODUCT_TYPE.virtual]: __('Starting at'),
        [PRODUCT_TYPE.bundle]: __('Starting at'),
        [PRODUCT_TYPE.grouped]: __('Starting at'),
        [PRODUCT_TYPE.downloadable]: __('Starting at'),
        [PRODUCT_TYPE.configurable]: __('As Low as')
    };

    renderPrice(price) {
        const {
            discountPercentage
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        if (discountPercentage !== 0) {
            return (
                <PriceSemanticElementName block="ProductPrice" elem="Price">
                    <span
                      itemProp={ itemProp }
                      content={ content }
                      block="ProductPrice"
                      elem="PriceValue"
                      data-productPrice={ priceFormatted }
                    >
                        { priceFormatted }
                    </span>
                </PriceSemanticElementName>
            );
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                <span mix={ { block: 'ProductPrice', elem: 'PriceBadge' } }>From</span>
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                  data-productPrice={ priceFormatted }
                >
                    { priceFormatted }
                </span>
            </PriceSemanticElementName>
        );
    }

    renderDiscountPercent() {
        const { discountPercentage } = this.props;

        if (discountPercentage === 0) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="DiscountPercent">{ `${Math.floor(discountPercentage)}% off` }</span>
        );
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label
        } = this.props;

        return (
            <>
                { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                { this.renderOldPrice() }
                { this.renderDiscountPercent() }
                { this.renderSchema() }
            </>
        );
    }

    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0
                } = {}
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
