/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-nested-ternary */
import CheckoutPayments from 'Component/CheckoutPayments';
import Form from 'Component/Form';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import {
    CheckoutBilling as SourceCheckoutBilling
} from 'SourceComponent/CheckoutBilling/CheckoutBilling.component';
import { formatPrice } from 'Util/Price';

/** @namespace Tigerone/Component/CheckoutBilling/Component */
export class CheckoutBillingComponent extends SourceCheckoutBilling {
    renderActions() {
        const {
            isOrderButtonVisible,
            isOrderButtonEnabled,
            isTermsAndConditionsAccepted
        } = this.state;

        const { termsAreEnabled } = this.props;

        if (!isOrderButtonVisible) {
            return null;
        }

        // if terms and conditions are enabled, validate for acceptance
        const isDisabled = termsAreEnabled
            ? !isOrderButtonEnabled || !isTermsAndConditionsAccepted
            : !isOrderButtonEnabled;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                { this.renderOrderTotal() }
                <button
                  type="submit"
                  block="Button"
                  disabled={ isDisabled }
                  mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                  id="CheckoutPlaceOrder"
                >
                    { __('Proceed to Payment') }
                </button>
            </div>
        );
    }

    renderPayments() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            isValidCardDetails
        } = this.props;

        if (!paymentMethods.length) {
            return (
                <span block="Checkout" elem="NoPaymentMethod">
                    { __('No Payment method available') }
                </span>
            );
        }

        return (
            <CheckoutPayments
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              paymentMethods={ paymentMethods }
              onPaymentMethodSelect={ onPaymentMethodSelect }
              setOrderButtonVisibility={ this.setOrderButtonVisibility }
              billingAddress={ shippingAddress }
              setOrderButtonEnableStatus={ this.setOrderButtonEnableStatus }
              isValidCardDetails={ isValidCardDetails }
            />
        );
    }

    renderAlertsection() {
        const {
            paymentMethod,
            companyData = {},
            totals: { grand_total } = {}
        } = this.props;

        if (!companyData) {
            return null;
        }

        const {
            company: {
                name = '',
                credit: {
                    allow_exceed_amount,
                    available_credit: { value = '', currency } = {}
                } = {}
            } = {}
        } = companyData;

        const availableCompany_credits = formatPrice(value, currency);
        const exceeded_amount = formatPrice(grand_total - value, currency);

        if (paymentMethod === 'companycredit') {
            return (
                <div block="CheckoutBilling" elem="AlertSection">
                    { allow_exceed_amount ? (
                        <>
                            { grand_total > value && (
                                <p>
                                    { ` ${ __('The credit limit for') } ${name} is ${availableCompany_credits}. 
                                    ${ __('It will be exceeded by') } ${exceeded_amount} ${ __('with this order.') }` }
                                </p>
                            ) }
                        </>
                    ) : (
                        <>
                            { value <= 0 || value < grand_total ? (
                                <p>
                                    { __('Payment On Account cannot be used for this order because your order amount exceeds your credit limits') }
                                </p>
                            ) : null }
                        </>
                    ) }
                </div>
            );
        }

        return null;
    }

    render() {
        const { onBillingSuccess } = this.props;

        return (
            <Form
              attr={ {
                  id: BILLING_STEP
              } }
              mix={ { block: 'CheckoutBilling' } }
              onSubmit={ onBillingSuccess }
            >
                { /* { this.renderAddresses() } */ }
                { this.renderPayments() }
                { this.renderTermsAndConditions() }
                { this.renderActions() }
                { this.renderPopup() }
                { this.renderAlertsection() }
            </Form>
        );
    }
}
export default CheckoutBillingComponent;
